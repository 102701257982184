import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuth = sessionStorage.getItem("isAuthenticated");
    return storedAuth ? JSON.parse(storedAuth) : false;
  });
  const [allowedRoute, setAllowedRoute] = useState(() => {
    return sessionStorage.getItem("allowedRoute") || null;
  });
  const navigate = useNavigate();
  const location = useLocation();

  const login = (email, password) => {
    if (email === "admin@lionorbit.com" && password === "admin@123") {
      setIsAuthenticated(true);
      setAllowedRoute("/home");
      sessionStorage.setItem("allowedRoute", "/home");
      navigate("/home");
    } else if (email === "admin@lionorbit.com" && password === "admin@1234") {
      setIsAuthenticated(true);
      setAllowedRoute("/homeold");
      sessionStorage.setItem("allowedRoute", "/homeold");
      navigate("/homeold");
    } else {
      alert("Incorrect credentials");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setAllowedRoute(null);
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    sessionStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  useEffect(() => {
    // Log out if the user navigates away from their allowed route
    if (isAuthenticated && allowedRoute && location.pathname !== allowedRoute) {
      logout();
    }
  }, [location, isAuthenticated, allowedRoute]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
